import React from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";

const MarkdownRenderer = ({ children }) => {
  // Convert Markdown to raw HTML
  const rawHtml = marked(children);

  // Sanitize the HTML to prevent XSS attacks
  const cleanHtml = DOMPurify.sanitize(rawHtml);

  // Render sanitized HTML
  return <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
};

export default MarkdownRenderer;
