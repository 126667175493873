const TextWidgetSearchOn = ({ searchTerm }) => {
    const externalSearchMap = {
        'Google': `https://www.google.com/search?q=${searchTerm}`,
        'Youtube': `https://www.youtube.com/results?search_query=${searchTerm}`,
        'Reddit': `https://www.reddit.com/search/?q=${searchTerm}`,
        'Wikipedia': `https://en.wikipedia.org/wiki/Special:Search?search=${searchTerm}`,
        'Google Scholar': `https://scholar.google.com/scholar?q=${searchTerm}`
    }
    return <div className="mb-3">
        <small className="mb-2 d-block">Search about on</small>
        <a className="navbar-item ms-0 me-2 mb-2" target="_blank" rel="noreferrer" href={externalSearchMap['Youtube']}>Youtube</a>
        <a className="navbar-item ms-0 me-2 mb-2" target="_blank" rel="noreferrer" href={externalSearchMap['Google']}>Google</a>
        <a className="navbar-item ms-0 me-2 mb-2" target="_blank" rel="noreferrer" href={externalSearchMap['Reddit']}>Reddit</a>
        <a className="navbar-item ms-0 me-2 mb-2" target="_blank" rel="noreferrer" href={externalSearchMap['Wikipedia']}>Wikipedia</a>
        <a className="navbar-item ms-0 me-2 mb-2" target="_blank" rel="noreferrer" href={externalSearchMap['Google Scholar']}>Google Scholar</a>
    </div>
}

export default TextWidgetSearchOn