import { useCallback } from 'react';
import changeGroup from '../helpers/changeGroup';
import fetchGraphDataForGame from '../helpers/fetchGraphDataForGame';
import filterGraph from '../helpers/filterGraph';
import focusOnNode from '../helpers/focusOnNode';
import getNeighborsGraphById from '../helpers/getNeighborsGraphById';
import { getNodeContent } from '../helpers/getNodeContent';
import highlightGraph from '../helpers/highlightGraph';
import refreshAllIds from '../helpers/refreshAllIds';
import { updateNodeInGraph } from '../helpers/updateNodeInGraph';
import { mergeGraphs } from '../helpers/mergeGraphs';
import findParents from '../helpers/findParents';
import { addOrUpdateGraph } from '../helpers/indexedDb';
import postGraphToServer from '../helpers/postGraphToServer';
import fitNodesOnScreen from '../helpers/fitNodesOnScreen';
import EdgeEntity from '../entities/EdgeEntity';
import addEdgeAndUpdateGraph from '../helpers/addEdgeAndUpdateGraph';
import { notifyAndInviteToSubscribe } from '../components/notifyAndInviteToSubscribe';

const useNodeSelection = ({
  isFetching,
  setIsFetching,
  graph,
  setGraph,
  alreadyLoadedNode,
  addAlreadyLoadedNode,
  nodeAlreadyLoaded,
  networkRef,
  topic,
  setNewGameState,
  setLastSelectedNode,
  setLastSelectedNodeParents,
  setShowNodeContent,
  handleNodeTimerRef,
  timeoutRefForGraphUpdate,
  setPhysicsEnabled,
  taskQueue,
  addingNewEdge,
  setAddingNewEdge,
  addingNewEdgeFrom,
  notice,
  setShowLoginModal
}) => {
  const handleNodeSelection = useCallback(
    async (event) => {
      if (handleNodeTimerRef.current) return;
      handleNodeTimerRef.current = setTimeout(() => {
        handleNodeTimerRef.current = null;
      }, 100);

      if (isFetching) return;

      const { nodes, skipFetch, initialNodeSteup } = event;
      const start = !skipFetch && initialNodeSteup
      const selectedNode = getNodeContent(graph, nodes[0]);
      setLastSelectedNode(selectedNode);

      if (addingNewEdge) {
        if (selectedNode && selectedNode.id !== addingNewEdgeFrom) {
          const edge = new EdgeEntity(addingNewEdgeFrom, selectedNode.id)
          addEdgeAndUpdateGraph(edge, graph, topic, setGraph)
        }

        setAddingNewEdge(false)
        return;
      }

      if (!selectedNode) return;

      const selectedNodeParents = findParents(graph, selectedNode.id);
      if (selectedNodeParents) {
        setLastSelectedNodeParents(selectedNodeParents[0]);
      }

      setIsFetching(true);
      changeGroup(selectedNode.id, 'loading', networkRef);

      taskQueue.addTask(async () => {
        if (timeoutRefForGraphUpdate.current) {
          clearTimeout(timeoutRefForGraphUpdate.current);
        }

        if (nodes.length > 0) {
          timeoutRefForGraphUpdate.current = setTimeout(async () => {
            setPhysicsEnabled(true);
            try {
              const neighbors = getNeighborsGraphById(selectedNode.id, graph);
              const topic_ = neighbors.nodes[0]?.label;
              const subtopic = selectedNode.label;

              if (start) {
                focusOnNode(1, networkRef, 0, 0, 1000);
              }

              var gameData

              if (!skipFetch) {
                gameData = await fetchGraphDataForGame(topic_ ?? topic, subtopic, start);
              }


              addAlreadyLoadedNode(selectedNode.id);
              setIsFetching(false);

              const newGroup = getGroup(gameData, selectedNode);
              changeGroup(selectedNode.id, newGroup, networkRef);

              let graphCopy = JSON.parse(JSON.stringify(graph));
              graphCopy = updateNodeInGraph(graphCopy, selectedNode.id, selectedNode);

              if (gameData) {
                const filteredGraph = filterGraph(gameData.graph, graphCopy, true);
                const refreshedData = refreshAllIds(filteredGraph, graphCopy.nodes.length + 1, selectedNode.id);
                const mergedGraph = mergeGraphs(graphCopy, refreshedData);
                addOrUpdateGraph(mergedGraph, topic);
                setGraph(mergedGraph);
                highlightGraph(gameData.challengePath, mergedGraph, networkRef);
                await postGraphToServer(topic, mergedGraph)
              }

              fitNodesOnScreen(networkRef);
            } catch (error) {
                if (error.status === 429) {
                  notifyAndInviteToSubscribe(notice, error, setShowLoginModal);
                }
                console.error(error);
                setIsFetching(false);
                const newGroup = getGroup(gameData, selectedNode);
                changeGroup(selectedNode.id, newGroup, networkRef);
            }
          }, 100);
        }
      });
    },
    [
      isFetching,
      graph,
      topic,
      addAlreadyLoadedNode,
      nodeAlreadyLoaded,
      networkRef,
      setNewGameState,
      setLastSelectedNode,
      setLastSelectedNodeParents,
      setShowNodeContent,
      setPhysicsEnabled,
      setIsFetching,
      setGraph,
      handleNodeTimerRef,
      timeoutRefForGraphUpdate,
      taskQueue
    ]
  );

  return handleNodeSelection;
};

export default useNodeSelection;

function getGroup(gameData, selectedNode) {
  const newGroup = gameData?.graph.nodes[0]?.group ??
    (selectedNode.group && selectedNode.group !== 'active' ? selectedNode.group : 'selected');
  selectedNode.group = newGroup;
  return newGroup;
}