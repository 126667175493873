export default function highlightGraph(pathToHighlight, graph, networkRef) {
    if (!pathToHighlight) {
        return graph
    }

    const nodesToUpdate = []

    const destination = pathToHighlight.at(-1)

    const newColor = '#32CD32'

    graph.nodes.forEach(node => {
        if (node.label === destination) {
            nodesToUpdate.push(node)
        }
    });

    if (networkRef.current) {
        const network = networkRef.current;
        const nodesDataset = network.body.data.nodes;

        nodesToUpdate.forEach(node => {
            nodesDataset.update({ id: node.id, color: { border: newColor } });
        });

        network.redraw();
    }
}