import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import '../style/ResponsiveCardSwiper.css';
import fetchHandCards from "../helpers/fetchHandCards";
import { generateRandomKey } from "../helpers/generateRandomKey";
import { IoCloseCircle } from "react-icons/io5";
import { notifyAndInviteToSubscribe } from "./notifyAndInviteToSubscribe";


const ResponsiveCardSwiper = ({
  topics,
  selectedCardId,
  setSelectedCardId,
  handCards,
  setHandCards,
  noticeService,
  refresh,
  setRefresh,
  graph,
  setLevelCardGame,
  swiperRef,
  multiplier,
  setShowLoginModal
}) => {
  const initialized = useRef();

  const resizeCards = () => {
    const handCards = document.querySelectorAll('.responsive-card-swiper');
    handCards.forEach(card => {
      card.style.overflow = null;
    });
  }

  useEffect(() => {
    if (initialized.current && !refresh) {
      return
    }

    initialized.current = true;

    const newGame = !refresh

    loadAndDisplayCards(newGame, noticeService, topics, setHandCards, resizeCards, graph.topic, setLevelCardGame, setShowLoginModal);

    setRefresh(false);

  }, [refresh, setRefresh, noticeService, topics, setHandCards, graph, setLevelCardGame, setShowLoginModal])

  if (!handCards || !handCards.length) {
    return
  }

  return (
<div
      className="responsive-card-swiper"
      style={{
        position: "absolute",
        bottom: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: "274px",
        overflow: "hidden"
      }}
    >
      <Swiper
        modules={[EffectCards]}
        effect={"cards"}
        grabCursor={true}
        className="hand-card-swiper"
        cardsEffect={{
          slideShadows: false
        }}
        onSlideChange={(swiper) => {
          setSelectedCardId(swiper.activeIndex);
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        speed={600}
      >
        {handCards.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div className={`hand-card ${slide.status ?? ""}`}>
              <div className="d-flex justify-content-between">
                <h1>{slide.title}</h1>
                <div className="card-point-wrapper"><div className="card-point-value">{multiplier ? multiplier * 100 : 100}</div><div className="card-point-tag">PTS</div></div>
              </div>
              <p>{slide.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {multiplier ?
        <div className="hand-card-multiplier d-flex justify-content-center">
          <div className="pulse">
            <div className={`number ${multiplier > 1 ? "" : "transparent"}`}>{multiplier}x</div>
          </div>
        </div> : null}
    </div>
  );
};

export default ResponsiveCardSwiper;

function loadAndDisplayCards(newGame, noticeService, topics, setHandCards, resizeCards, mainTopic, setLevelCardGame, setShowLoginModal) {
  const key = generateRandomKey();

  noticeService.open({
    key: key,
    duration: null,
    content: (
      <div>
        <div className="d-flex justify-content-between px-2">
          <small className="tag">Challenge</small>
          <strong>Wait a second</strong>
        </div>
        <div>Getting your cards...</div>
      </div>
    ),
    closable: true,
    closeIcon: <IoCloseCircle />,
  });

  const fetchData = async () => {
    try {
      const response = await fetchHandCards({ newGame, mainTopic, topics });
      setLevelCardGame(response.level)
      setHandCards(response.cards)
      setTimeout(() => { resizeCards(); }, 50);

      const newNoticeKey = generateRandomKey();

      noticeService.open({
        key: newNoticeKey,
        duration: 10,
        content: (
          <div>
            <div className="d-flex justify-content-between px-2">
              <small className="tag">Challenge</small>
              <strong>Ready, set, play!</strong>
            </div>
            <div>Use a card on the node to match it with the correct one.</div>
          </div>
        ),
        closable: true,
        showProgress: false,
        closeIcon: <IoCloseCircle />,
      });
      noticeService.close(key);
    } catch (error) {
      console.error(error)
      noticeService.close(key);
      if (error.status === 429) {
        notifyAndInviteToSubscribe(noticeService, error, setShowLoginModal);
        return
      }
    }
  };

  fetchData();
}