import { useParams } from "react-router-dom";
import Modal from "./Modal";
import { useEffect } from "react";
import { setCookieBase64 } from "./setCookieBase64";
import { IoBagCheckOutline } from "react-icons/io5";
import constants from "../helpers/constants";

const CheckoutSuccess = () => {
    const { access } = useParams();

    useEffect(() => {
        const updateSession = async () => {
            try {
                const response = await fetch(
                    `${constants.fetchAuthRefresh}`,
                    { credentials: "include" });
                    
                if (response.ok) {
                    const result = await response.json();
                    setCookieBase64("auth", result, { days: 1 })
                }
            } catch (error) {
                console.error("Error fetching graph from server:", error);
            }
        }
        updateSession();
    }, [])

    if (!access) {
        return;
    }

    return (
        <Modal title="Checkout" hideCloseButton={true}>
            <div className="login-modal">
                <div className="login-modal-container">
                    <div className="d-flex">
                        <h1 className="me-2 mt-1"><IoBagCheckOutline /></h1>
                        <h1>Payment Successfully Processed</h1>
                    </div>
                    <p style={{ padding: '2em', textAlign: 'center' }}>Your access has been upgraded to the {access} plan, unlocking exclusive features and benefits.</p>
                    <button onClick={() => { window.location = '/' }} className="highlight">Continue</button>
                </div>
            </div>
        </Modal>
    )
}
export default CheckoutSuccess