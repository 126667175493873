import { useEffect } from 'react';
import { updateEdges } from '../helpers/updateEdges';
import { updateNodes } from '../helpers/updateNodes';

// Handles graph state updates whenever the graph changes.
export function useHandleGraphUpdates(graph, networkRef, useHandleGraphUpdatesTimerRef) {

  useEffect(() => {
    try{
    if (useHandleGraphUpdatesTimerRef.current) return;
    useHandleGraphUpdatesTimerRef.current = true

    updateNodes(graph, networkRef);
    updateEdges(graph, networkRef);

    useHandleGraphUpdatesTimerRef.current = undefined
    } catch (error){
      console.error(error)
    }
  }, [graph]);
}
