import updateGraph from "./updateGraph";

const editNodeAndUpdateGraph = (updatedNode, graph, setGraph) => {
    let newGraph = JSON.parse(JSON.stringify(graph));

    const nodeIndex = newGraph.nodes.findIndex(node => node.id === updatedNode.id);

    if (nodeIndex !== -1) {
        newGraph.nodes[nodeIndex] = { ...newGraph.nodes[nodeIndex], ...updatedNode.toObject() };
        setGraph(newGraph);
        updateGraph({ topic: graph.topic, newGraph });
    } else {
        console.error("Node not found: Unable to update.");
    }
};

export default editNodeAndUpdateGraph;
