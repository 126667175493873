import updateGraph from "./updateGraph"

const removeNodeAndUpdateGraph = (nodeId, topic, graph, setGraph) => {
    let newGraph = JSON.parse(JSON.stringify(graph));
    newGraph.nodes = newGraph.nodes.filter(node => node.id !== nodeId);
    newGraph.edges = newGraph.edges.filter(edge => edge.from !== nodeId && edge.to !== nodeId);
    setGraph(newGraph);
    updateGraph({ topic, newGraph })
}

export default removeNodeAndUpdateGraph