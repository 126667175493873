import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import '../style/SearchPage.css';
import TopicsGrid from './TopicsGrid';
import Navbar from './Navbar';
import LoginModal from './LoginModal';
import { useNotification } from 'rc-notification';
import { motion } from '../helpers/motion';
import NotificationContainer from './NotificationContainer';
import { useCloseModalListener } from '../hooks/useCloseModalListener';
import TopicsGridHistory from './TopicsGridHistory';
import constants from '../helpers/constants';
import { setCookieBase64 } from './setCookieBase64';
import { useLocation } from 'react-router-dom';
import { IoCloseCircle } from 'react-icons/io5';

const HomePage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);

  useCloseModalListener([setShowLoginModal]);

  const [notice, contextHolder] = useNotification({
    showProgress: true,
    getContainer: () => document.getElementById('notification-container'),
    motion,
  });

  useEffect(() => {
    const accountConfirmationSucces = queryParams.get('accountConfirmationSucces')
    if (accountConfirmationSucces) {
      const updateSession = async () => {
        try {
          const response = await fetch(
            `${constants.fetchAuthRefresh}`,
            { credentials: "include" });

          if (response.ok) {
            const result = await response.json();
            setCookieBase64("auth", result, { days: 1 })
            notice.open({
              content: (
                <div>
                  <div className="d-flex justify-content-between px-2 mb-2">
                    <small className="tag">Phew!</small>
                    <strong>Successful Account Confirmation</strong>
                  </div>
                  <div>Your account has been successfully confirmed! Welcome aboard!</div>
                </div>
              ),
              duration: 30,
              closable: true,
              showProgress: false,
              closeIcon: <IoCloseCircle />,
            });
          }
        } catch (error) {
          console.error("Error from server:", error);
        }
      }
      updateSession();
    }
  }, [])

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      const savedTopics = JSON.parse(localStorage.getItem("lastClickedTopics")) || [];
      const updatedTopics = [...new Set([{ name: query, emoji: "" }, ...savedTopics])].slice(0, 9);
      localStorage.setItem("lastClickedTopics", JSON.stringify(updatedTopics));
      navigate(`/${encodeURIComponent(query)}`);
    }
  };

  return (
    <div>
      <NotificationContainer>
        {contextHolder}
      </NotificationContainer>
      <Navbar
        isHome={true}
        setShowLoginModal={setShowLoginModal}
      />
      <div id="SearchPage" className='py-5'>
        <div className='d-flex w-100 justify-content-center pt-5'>
          <div className='title-wrapper d-inline-block pt-0 pt-sm-5'>
            <div className='d-flex justify-content-center align-items-center pt-5'>
              <img src="./logo512.png" alt="Logo" className="logo" />
              <h1 className="logo-title text-light display-1 bold">Nodecharm</h1>
            </div>
          </div>
        </div>
        <div className="container text-center" style={{ maxWidth: '400px' }}>
          <form onSubmit={handleSearch} className="d-flex">
            <input
              type="text"
              className="form-control mr-2 me-2 mt-0 background-dark cursor-text"
              placeholder="Type something..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button type="submit" className="col-3 navbar-item ms-0 mt-0">
              Create
            </button>
          </form>
          <div className='warning'>
            <small>AI can make mistakes.</small>
          </div>
        </div>
        <div className='container mt-5'>
          <TopicsGridHistory />
        </div>
        <div className='container'>
          <TopicsGrid />
        </div>
      </div>
      {showLoginModal && (
        <LoginModal notification={notice} topic={undefined} />
      )}
    </div>
  );
};

export default HomePage;
