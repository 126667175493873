export const getCookieBase64 = (name) => {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
        const [key, value] = cookie.split("=");
        if (key === name) {
            return JSON.parse(atob(value));
        }
    }
    return null;
};
