import { useState, useEffect, useRef, useCallback } from "react";

const useAnimatedPoints = (totalPoints) => {
  const [displayPoints, setDisplayPoints] = useState(0);
  const animationRef = useRef(null);

  const animatePoints = useCallback((start, end) => {
    const step = (end - start) / 150;
    let current = start;

    if (animationRef.current) {
      clearInterval(animationRef.current);
    }

    animationRef.current = setInterval(() => {
      current += step;
      if ((step > 0 && current >= end) || (step < 0 && current <= end)) {
        current = end;
        clearInterval(animationRef.current);
      }
      setDisplayPoints(Math.floor(current));
    }, 16);
  }, []);

  useEffect(() => {
    animatePoints(displayPoints, totalPoints);
    return () => {
      if (animationRef.current) clearInterval(animationRef.current);
    };
  }, [totalPoints, animatePoints]);

  return displayPoints;
};

export default useAnimatedPoints;
