import React, { useEffect, useRef, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import fetchNodeContent from '../helpers/fetchNodeContent';
import TextWidgetSearchOn from './TextWidgetSearchOn';
import 'react-loading-skeleton/dist/skeleton.css'
import '../style/NodeContent.css';
import editNodeAndUpdateGraph from '../helpers/editNodeAndUpdateGraph';
import NodeEntity from '../entities/NodeEntity';
import { notifyAndInviteToSubscribe } from './notifyAndInviteToSubscribe';

const Title = ({ node, parentNode, graph, setGraph }) => {
  const [value, setValue] = useState(node.label);
  const textareaRef = useRef(null);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    adjustHeight();
  }, []);

  const handleInputChange = (e) => {
    setValue(e.target.value);
    adjustHeight();

    node.label = e.target.value;
    const updatedNode = new NodeEntity({
      id: node.id,
      label: node.label
    })
    editNodeAndUpdateGraph(updatedNode, graph, setGraph)
  };

  return <div>
    <div>{parentNode ? parentNode.label : graph.topic}</div>
    <textarea
      ref={textareaRef}
      value={value}
      onChange={handleInputChange}
      rows="1"
    >
      {value}
    </textarea>
  </div>
}

const NodeContent = ({ node, parentNode, graph, setGraph, notice, setShowLoginModal, setShowNodeContent, setShowChat }) => {
  const initialized = useRef();
  const [content, setContent] = useState({});

  useEffect(() => {
    if (node.content) {
      return
    }

    if (initialized.current) {
      return
    }

    initialized.current = true;

    const fetchData = async () => {
      try {
        const nodeContentData = await fetchNodeContent(parentNode ? parentNode.label : graph.topic, node.label)
        setContent(nodeContentData)
      } catch (error) {
        if (error.status === 429) {
          setContent({ explanation: error.message })
          notifyAndInviteToSubscribe(notice, error, setShowLoginModal);
          return
        }
      }
    }

    fetchData()

  }, [])

  const handleChatAboutClick = () => {
    setShowNodeContent(false)
    setShowChat(true)
  }

  return <div className='node-content-modal'>
    <div className="node-content-modal-container">
      <Title
        node={node}
        parentNode={parentNode}
        graph={graph}
        setGraph={setGraph}
      />
      {
        JSON.stringify(content) === '{}' ?
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton count={3} />
          </SkeletonTheme>
          :
          <div>{content.explanation}</div>
      }
      <button onClick={handleChatAboutClick} className="navbar-item ms-0 me-2 mb-2">Chat about</button>
      <TextWidgetSearchOn searchTerm={`${node.label === graph.topic ? "" : node.label} ${graph.topic}`} />
    </div>
  </div>
};

export default NodeContent