const constants = {
    fetchNodeChat: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/node/chat",
    fetchNodeContent: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/node/content",
    
    fetchGraphUrl: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/graph",

    fetchGraphGameUrl: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/game",
    fetchGraphGameAnswerUrl: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/game?answer=true",
    fetchGraphGameHintUrl: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/game/hint",

    
    fetchHandCardsUrl: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/cardgame/hand-cards",
    postUseCardUrl: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/cardgame/use-card",
    
    fetchLogin: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/auth/login",
    fetchAuthRefresh: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/auth/refresh",
    fetchRegister: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/auth/register",
    fetchPasswordRecovery: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/auth/password-recovery",
    fetchResetPassword: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/auth/reset-password",
    fetchAuthLogout: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/auth/logout",
    

    fetchPaymentCreateCheckout: (process.env.REACT_APP_API_URL || "https://www.nodecharm.com") + "/api/payment/checkout-session",

    splitStringEveryNChars: 40,
    baseIntervalForWordHighlight: 60,
    hintTime: 20,
    answerTime: 30,
    graph: {
        options: {
            nodes: {
                color: {
                    border: '#666666',
                    background: '#191919',
                    hover: {
                        border: '#32CD32',
                        background: '#32CD32',
                    },
                    highlight: {
                        border: '#32CD32',
                        background: '#32CD32',
                    },
                },
                shape: 'dot',
                font: {
                    size: 22,
                    color: '#777',
                    face: 'Arial',
                    multi: true
                },
                scaling: {
                    min: 20,
                    max: 150,
                },
            },
            layout: {
                hierarchical: false
            },
            edges: {
                color: {
                    color: "#363636",
                    hover: "#32CD32",
                    highlight: "#32CD32",
                },
                arrows: {
                    to: { enabled: true, scaleFactor: 1 }
                },
                smooth: {
                    type: 'continuous',
                    forceDirection: 'none',
                    roundness: 0.5,
                },
                width: 1,
                hoverWidth: 1,
                selectionWidth: 1
            },
            height: "100vh",
            interaction: {
                tooltipDelay: 99999999999999999999,
                hover: true,
                multiselect: true,
                selectConnectedEdges:false
            },
            physics: {
                enabled: true,
                solver: 'forceAtlas2Based',
                forceAtlas2Based: {
                    gravitationalConstant: -1000,
                    centralGravity: 0.01,
                    springLength: 200,
                    springConstant: 0.08
                },
                stabilization: {
                    enabled: true,
                    iterations: 30,
                    updateInterval: 25,
                },
            },
            groups: {
                loading: {
                    shape: 'custom',
                    ctxRenderer: ({ ctx, x, y, id }) => {
                        ctx.font = '22px Arial';
                        ctx.fillStyle = '#ffffff77';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.fillText('Loading...', x, y);
                        return true;
                    },
                },
                default: {
                    shape: 'dot',
                    font: {
                        size: 22,
                        color: '#ffffff77',
                        face: 'Arial',
                        multi: true
                    },
                },
                selected: {
                    shape: 'dot',
                    size: 55,
                    color: {
                        border: '#666666',
                        background: '#191919'
                    }
                },
                main: {
                    shape: 'dot',
                    size: 99,
                    color: {
                        border: '#32CD32',
                        background: '#191919'
                    }
                },
                active: {
                    shape: 'dot',
                    size: 22,
                    color: {
                        border: '#191919',
                        background: '#666666'
                    }
                }
            }
        },

        nodes: {
            style: {
                selected: {
                    font: {
                        color: '#ffffff',
                        strokeWidth: 1,
                        strokeColor: "#000000"
                    },
                }
            }
        },
        edges: {
            style: {
                normal: {
                    color: '#ffffff2f'
                },
                selected: {
                    color: "#87EC65"
                },
                from: {
                    color: '#434343'
                },
                to: {
                    color: '#00BFFF7f'
                }
            }
        }
    }
}

export default constants