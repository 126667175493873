import { useEffect } from 'react';
import changeGroup from '../helpers/changeGroup';
import getOrSetGraph from '../helpers/getOrSetGraph';

export function useInitialNodeSetup(networkRef, timerRef, handleNodeSelection, setGraph, topic) {
  useEffect(() => {
    if (timerRef.current !== undefined){return}
    timerRef.current = true;

    changeGroup(1, 'loading', networkRef);
    const start = async () => {
      const [graphData, cached] = await getOrSetGraph(topic)
      if (cached) {
        setGraph(graphData)
        handleNodeSelection({ nodes: [1], initialNodeSteup: true, skipFetch: true });
      } else {
        handleNodeSelection({ nodes: [1], initialNodeSteup: true });
      }
    }

    start()
  }, []);
}
