import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import '../style/LoginModal.css';
import constants from "../helpers/constants";
import { IoCloseCircle } from "react-icons/io5";
import { setCookieBase64 } from "./setCookieBase64";
import { getCookieBase64 } from "./getCookieBase64";

const LoginModal = ({ notification, resetPasswordToken, topic }) => {
    const [auth, setAuth] = useState()
    const [isLogin, setIsLogin] = useState(true)
    const [isPasswordRecovery, setIsPasswordRecovery] = useState(false)
    const [formData, setFormData] = useState({ email: "", password: "" })
    const [fetching, setFetching] = useState(false);
    const [actionUrl, setActionUrl] = useState("");

    useEffect(() => {
        const recoverSession = () => {
            try {
                const cookie = getCookieBase64('auth')

                if (cookie) {
                    setAuth(cookie);
                }
            } catch (err) {
                console.error("Error decoding session:", err);
                setAuth(null);
            }
        };

        recoverSession();
    }, []);

    const toggleMode = () => {
        if (fetching) { return }
        setIsLogin((prevMode) => !prevMode);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLogout = async () => {
        await fetch(constants.fetchAuthLogout, {
            method: "POST",
            credentials: "include"
        });

        document.cookie = "auth=; path=/; max-age=0";
        window.location.reload();
    };

    const handleSubmit = async () => {
        if (fetching) { return }

        var endpoint = isLogin ? constants.fetchLogin : constants.fetchRegister
        if (isPasswordRecovery) {
            endpoint = constants.fetchPasswordRecovery
        }

        const method = "POST";
        const headers = { "Content-Type": "application/json" };

        try {
            setFetching(true)

            const response = await fetch(endpoint, {
                method,
                headers,
                body: JSON.stringify(formData),
                credentials: "include"
            });

            const result = await response.json();

            const statusMessages = {
                200: "Success! You're now logged in. Welcome back!",
                401: "Login failed. The password you entered is incorrect. Please try again.",
                403: "Login failed. Your email is not confirmed. Please check your inbox and confirm your email.",
            };

            const title = result.message

            const message = title === "Login successful" ? statusMessages[response.status] || "An unexpected error occurred. Please try again."
                : "";

            const tag = response.status === 200 ? "Phew!" : "Ops!";
            if (result.message) {
                notification.open({
                    content: (
                        <div>
                            <div className="d-flex justify-content-between px-2 mb-2">
                                <small className="tag">{tag}</small>
                                <strong>{title}</strong>
                            </div>
                            <div>{message}</div>
                        </div>
                    ),
                    duration: 30,
                    closable: true,
                    showProgress: false,
                    closeIcon: <IoCloseCircle />,
                });
            }

            if (response.ok) {
                if (isLogin && !isPasswordRecovery) {
                    setCookieBase64("auth", result, { days: 1 })
                }
            } else {
                console.error(result.error || "Something went wrong. Please try again.");
            }

            document.dispatchEvent(new CustomEvent('closeModal'));
        } catch (error) {
            console.error("Network error. Please try again.");
        } finally {
            setFetching(false)
        }
    };

    function getButtonText() {
        if (fetching) return 'Processing...';
        if (resetPasswordToken) return 'Confirm';
        if (isPasswordRecovery) return 'Send email for recovery';
        if (isLogin) return 'Login';
        return 'Register';
    }

    useEffect(() => {
        const fetchUserCountry = async () => {
            try {
                const response = await fetch("https://ipapi.co/json/");
                const data = await response.json();
                const country = data.country || "US"; // Fallback if no country is found
                setActionUrl(`${constants.fetchPaymentCreateCheckout}?country=${country}`);
            } catch (error) {
                console.error("Error fetching user country:", error);
                setActionUrl(`${constants.fetchPaymentCreateCheckout}?country=US`);
            }
        };

        fetchUserCountry();
    }, []);

    if (auth) {
        return (<Modal title="Account">
            <div className="login-modal">
                <div className="login-modal-container">
                    <pre>{JSON.stringify(auth, null, 4)}</pre>
                    {auth.access == "free" ? <form action={actionUrl} method="POST">
                        <input type="hidden" id="email" name="email" value={auth.email}></input>
                        <input type="hidden" id="topic" name="topic" value={topic}></input>
                        <button type="submit" className="highlight w-100">Subscribe Now</button>
                    </form> : null}
                    <button onClick={handleLogout}>Logout</button>
                </div>
            </div>
        </Modal>
        )
    }

    if (isPasswordRecovery) {
        return (
            <Modal title="Password recovery">
                <div className="login-modal">
                    <div className="login-modal-container">
                        <input onChange={handleInputChange} name="email" type="email" placeholder="Email" />
                        <button onClick={handleSubmit} className="highlight">{getButtonText()}</button>
                        {!isPasswordRecovery ? <>                        <button
                            className="btn-switch"
                            onClick={toggleMode}
                        >
                            {isLogin ? "Don't have an account? Register" : "Already have an account? Log In"}
                        </button>
                            <button
                                className="btn-switch"
                                onClick={() => { setIsPasswordRecovery(true) }}
                            >
                                Forgot your password?
                            </button></> : <button
                                className="btn-switch"
                                onClick={() => { setIsPasswordRecovery(false) }}
                            >
                            Back
                        </button>}
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Modal title={isLogin ? "Log In" : "Register"}>
            <div className="login-modal">
                <div className="login-modal-container">
                    <input onChange={handleInputChange} name="email" type="email" placeholder="Email" />
                    <input onChange={handleInputChange} name="password" type="password" placeholder={isLogin ? "Password" : "Create password"} />
                    <button onClick={handleSubmit} className="highlight">{getButtonText()}</button>
                    <button
                        className="btn-switch"
                        onClick={toggleMode}
                    >
                        {isLogin ? "Don't have an account? Register" : "Already have an account? Log In"}
                    </button>
                    <button
                        className="btn-switch"
                        onClick={() => { setIsPasswordRecovery(true) }}
                    >
                        Forgot your password?
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default LoginModal;
