export function mergeGraphs(oldGraph, newGraph) {
    const mergedGraph = { nodes: [], edges: [], ...oldGraph, ...newGraph };

    // Merge nodes
    mergedGraph.nodes = [...oldGraph.nodes, ...newGraph.nodes];

    // Merge edges
    mergedGraph.edges = [...oldGraph.edges, ...newGraph.edges];

    // Merge other attributes
    // for (const key in oldGraph) {
    //     if (!['nodes', 'edges'].includes(key)) {
    //         if (newGraph.hasOwnProperty(key)) {
    //             // You can adjust this merging logic based on how you want to handle conflicts
    //             mergedGraph[key] = Array.isArray(oldGraph[key]) 
    //                 ? [...oldGraph[key], ...newGraph[key]] 
    //                 : { ...oldGraph[key], ...newGraph[key] };
    //         } else {
    //             mergedGraph[key] = oldGraph[key];
    //         }
    //     }
    // }

    // If newGraph has additional keys not present in oldGraph
    for (const key in newGraph) {
        if (!['nodes', 'edges'].includes(key)) {
            mergedGraph[key] = newGraph[key];
        }
    }

    return mergedGraph;
}
