const { default: constants } = require("./constants");

const postGraphToServer = async (topic, data) => {
    data.key = topic;
    try {
        await fetch(constants.fetchGraphUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify({ data })
        });
    } catch (error) {
        console.error("Error posting graph to server:", error);
    }
    return null;
};

export default postGraphToServer