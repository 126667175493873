import { useEffect } from 'react';

export function useCloseModalListener(states) {
  useEffect(() => {
    const handleCloseModal = () => {
      (Array.isArray(states) ? states : [states])
        .filter((setState) => typeof setState === 'function')
        .forEach((setState) => setState(false));
    };

    document.addEventListener('closeModal', handleCloseModal);

    return () => {
      document.removeEventListener('closeModal', handleCloseModal);
    };
  }, [states]);
}
