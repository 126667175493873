import constants from "./constants";

const fetchHandCards = async (body) => {
    const response = await fetch(
        `${constants.fetchHandCardsUrl}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: "include",
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        response.message = await response.json()
        throw response;
    }

    const parsedResponse = await response.json()
    return parsedResponse;
}

export default fetchHandCards