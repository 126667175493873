class NodeEntity {
    constructor({ id = null, label, title, end_of_the_road = false, is_antithesis = false, is_main_topic = false }) {
        this._id = id;
        this._label = label;
        this._title = title;
        this._endOfTheRoad = end_of_the_road;
        this._isAntithesis = is_antithesis;
        this._isMainTopic = is_main_topic;
    }

    // Getters and setters for id
    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    // Getters and setters for label
    get label() {
        return this._label;
    }

    set label(value) {
        this._label = value;
    }

    // Getters and setters for title
    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }

    // Getters and setters for endOfTheRoad
    get endOfTheRoad() {
        return this._endOfTheRoad;
    }

    set endOfTheRoad(value) {
        this._endOfTheRoad = value;
    }

    // Getters and setters for isAntithesis
    get isAntithesis() {
        return this._isAntithesis;
    }

    set isAntithesis(value) {
        this._isAntithesis = value;
    }

    // Getters and setters for isMainTopic
    get isMainTopic() {
        return this._isMainTopic;
    }

    set isMainTopic(value) {
        this._isMainTopic = value;
    }

    // Method to display the entity as a plain object
    toObject() {
        return {
            id: this._id,
            label: this._label,
            title: this._title,
            end_of_the_road: this._endOfTheRoad,
            is_antithesis: this._isAntithesis,
            is_main_topic: this._isMainTopic,
        };
    }
}

export default NodeEntity