import React from "react"
import "../style/GamePointsDisplay.css"
import useAnimatedPoints from "../hooks/useAnimatedPoints"

const GamePointsDisplay = ({ totalPoints }) => {
  const displayPoints = useAnimatedPoints(totalPoints)

  return (
    <div className="game-points-wrapper">
      <div className={`game-points-container`}>
        <div className={"points-small"}>
          <small>Exp: {displayPoints}</small>
        </div>
      </div>
    </div>
  )
}

export default GamePointsDisplay
