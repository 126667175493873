class EdgeEntity {
    constructor(from, to) {
        this._from = from;
        this._to = to;
    }

    // Getter for 'from'
    get from() {
        return this._from;
    }

    // Setter for 'from'
    set from(value) {
        this._from = value;
    }

    // Getter for 'to'
    get to() {
        return this._to;
    }

    // Setter for 'to'
    set to(value) {
        this._to = value;
    }

    toObject() {
        return {
            from: this._from,
            to: this._to
        };
    }
}

export default EdgeEntity