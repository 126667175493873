import constants from "./constants";

const postUseCard = async (body) => {
    const response = await fetch(
        `${constants.postUseCardUrl}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: "include",
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        console.error(response);
    }
    
    const parsedResponse = await response.json()
    return parsedResponse;
}

export default postUseCard