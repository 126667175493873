import constants from "./constants";

export default async function deleteTopicFromServer(topic) {
    try {
        await fetch(
            `${constants.fetchGraphUrl}?topic=${topic}`,
            {
                method: "DELETE",
                credentials: "include"
            });
    } catch (error) {
        console.error("Error deleting graph from server:", error);
    }
}