import { useEffect } from "react";
import { IoCloseCircle } from "react-icons/io5";
import constants from "../helpers/constants";
import focusOnNode from "../helpers/focusOnNode";
import pulseNode from "../helpers/pulseNode";
import scheduleAnswer from "../helpers/scheduleAnswer";
import updateGameSession from "../helpers/updateGameSession";
import getHintFromServer from "../helpers/getHintFromServer";

export default function useHandleNewGameState({
    newGameState,
    setNewGameState,
    setGameState,
    networkRef,
    notice,
    pulseNodeTimerRef,
    answerNotificationTimerRef,
    taskQueue,
    topic
}) {
    useEffect(() => {
        if (JSON.stringify(newGameState) === "{}") return;


        // Update game session periodically, unless the game ended
        let updateSessionTimeout;
        taskQueue.start();
        
        // Merge new state
        setGameState((prev) => ({ ...prev, ...newGameState }));

        return () => {
            if (updateSessionTimeout) clearTimeout(updateSessionTimeout);
        };
    }, [newGameState]);
}
