function areNodesEqual(node1, node2) {
  return node1.label === node2.label && node1.color === node2.color; // Example comparison
}

export function updateNodes(newGraph, networkRef) {
  const currentNodes = networkRef.current.body.data.nodes.get(); // Get current nodes in the network
  const currentNodeIds = currentNodes.map(node => node.id);

  // Find nodes to add
  const newNodeIds = newGraph.nodes.map(node => node.id);
  const nodesToAdd = newGraph.nodes.filter(node => !currentNodeIds.includes(node.id));

  // Add new nodes
  nodesToAdd.forEach((node) => {
    networkRef.current.body.data.nodes.add(node);
  });

  // Find nodes to remove
  const nodesToRemove = currentNodes.filter(node => !newNodeIds.includes(node.id));

  // Remove nodes
  nodesToRemove.forEach((node) => {
    networkRef.current.body.data.nodes.remove(node.id);
  });

  // Find nodes to update
  const nodesToUpdate = newGraph.nodes.filter(node => {
    const currentNode = currentNodes.find(n => n.id === node.id);
    return currentNode && !areNodesEqual(currentNode, node);
  });

  // Update nodes
  nodesToUpdate.forEach((node) => {
    networkRef.current.body.data.nodes.update(node);
  });
}
