import { useParams } from "react-router-dom";
import Modal from "./Modal";
import { useState } from "react";
import constants from "../helpers/constants";
import { setCookieBase64 } from "./setCookieBase64";

const ResetPassword = () => {
    const { token } = useParams();
    const [formData, setFormData] = useState({ password: "" })
    const [fetching, setFetching] = useState(false);
    const [lastMessage, setLastMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        if (fetching) { return }

        const endpoint = `${constants.fetchResetPassword}?token=${token}`
        const method = "POST";
        const headers = { "Content-Type": "application/json" };

        try {
            setFetching(true)

            const response = await fetch(endpoint, {
                method,
                headers,
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok) {
                setCookieBase64("auth", result, { days: 1 })
                window.location.href = '/';
            } else {
                console.error(result.error || "Something went wrong. Please try again.");
            }

            if (result.message){
                setLastMessage(result.message)
            }
        } catch (error) {
            console.error("Network error. Please try again.");
        } finally {
            setFetching(false)
        }
    };

    return (
        <Modal title="Reset password" hideCloseButton={true}>
            <div className="login-modal">
                <div className="login-modal-container">
                    <pre>{lastMessage}</pre>
                    <input onChange={handleInputChange} name="newPassword" type="password" placeholder="Create new password" />
                    <button onClick={handleSubmit} className="highlight">{fetching ? "Processing..." : "Confirm"}</button>
                </div>
            </div>
        </Modal>
    )
}
export default ResetPassword