import { edgeExists } from './edgeExists';

export function updateEdges(newGraph, networkRef) {
  // Remove base on Node parent
  // Remove base on Node parent
  // Remove base on Node parent
  // Remove base on Node parent

  // Filter new nodes to add
  let filteredNewNodes = newGraph.nodes.filter(node => !networkRef.current.body.nodeIndices.includes(node.id));
  filteredNewNodes.forEach((node) => {
    networkRef.current.body.data.nodes.add(node);
  });

  // Filter new edges to add
  const edges = networkRef.current.body.data.edges.get();
  const filteredNewEdges = newGraph.edges.filter(edge => !edgeExists(edge, edges));
  filteredNewEdges.forEach((edge) => {
    networkRef.current.body.data.edges.add(edge);
  });

  // Remove edges where one of the nodes has been removed
  const updatedNodeIds = newGraph.nodes.map(node => node.id);
  const edgesToRemove = edges.filter(edge => {
    return !updatedNodeIds.includes(edge.from) || !updatedNodeIds.includes(edge.to);
  });

  edgesToRemove.forEach((edge) => {
    networkRef.current.body.data.edges.remove(edge);
  });

  // Remove base on Edge ids
  // Remove base on Edge ids
  // Remove base on Edge ids
  // Remove base on Edge ids
  const currentEdges = networkRef.current.body.data.edges.get(); // Get current edges in the network
  const currentEdgeIds = currentEdges.map(edge => edge.id);

  // Find edges to add
  const newEdgeIds = newGraph.edges.map(edge => edge.id);
  const edgesToAdd = newGraph.edges.filter(edge => !currentEdgeIds.includes(edge.id));

  // Add new edges
  edgesToAdd.forEach((edge) => {
    networkRef.current.body.data.edges.add(edge);
  });

  // Find edges to remove
  const edgesToRemove_ = currentEdges.filter(edge => !newEdgeIds.includes(edge.id));

  // Remove edges
  edgesToRemove_.forEach((edge) => {
    networkRef.current.body.data.edges.remove(edge.id);
  });
}