import './style/App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import GameGraph from './components/GameGraph';
import ResetPassword from './components/ResetPassword';
import CheckoutSuccess from './components/CheckoutSuccess';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/checkout/success/:access" element={<CheckoutSuccess />} />
        <Route path="/:topic" element={<GameGraph />} />
      </Routes>
    </Router>
  );
};

export default App;
