import React, { useRef, useEffect } from "react";

const SparkleEffect = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let sparkles = [];
    const maxSparkles = 500;

    // Resize canvas dynamically
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    window.addEventListener("resize", handleResize);

    // Create a sparkle object
    const createSparkle = (x, y, speed) => {
      const angle = Math.random() * Math.PI * 2;
      const velocity = (Math.random() - 0.5) * 30 * speed;

      return {
        x,
        y,
        radius: Math.random() * 3 + 6,
        alpha: Math.random() * 0.7 + 0.3,
        vx: Math.cos(angle) * velocity,
        vy: Math.sin(angle) * velocity,
        life: Math.random() * 100 + 50,
        maxLife: Math.random() * 100 + 50,
      };
    };

    // Add sparkles
    const addSparkles = (count, x, y, speed) => {
      for (let i = 0; i < count; i++) {
        if (sparkles.length < maxSparkles) {
          sparkles.push(createSparkle(x, y, speed));
        }
      }
    };

    const tick = () => {
      // Clear the canvas without adding a background color
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    
      sparkles = sparkles.filter((sparkle) => sparkle.life > 0);
    
      sparkles.forEach((sparkle) => {
        sparkle.life--;
    
        // Update position
        sparkle.x += sparkle.vx * 1;
        sparkle.y += sparkle.vy * 1;
    
        // Apply gravity
        sparkle.vy += 0.1;
    
        // Bounce off edges
        if (sparkle.x < 0 || sparkle.x > canvas.width) sparkle.vx *= -1;
        if (sparkle.y > canvas.height) sparkle.vy *= -(Math.random() * 0.1 + 0.8);
    
        // Draw sparkle
        ctx.beginPath();
        ctx.arc(sparkle.x, sparkle.y, sparkle.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(50, 205, 50, ${sparkle.alpha * (sparkle.life / sparkle.maxLife)})`;
        ctx.fill();
      });
    
      requestAnimationFrame(tick);
    };

    addSparkles(100, canvas.width / 2, canvas.height / 2, 1);
    tick();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ display: "block" }} />;
};

export default SparkleEffect;
