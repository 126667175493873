export const setCookieBase64 = (name, value, options = {}) => {
    const { days, path = "/", secure = true, sameSite = "Strict" } = options;

    const encodedValue = btoa(JSON.stringify(value));

    let cookieString = `${name}=${encodedValue}`;

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        cookieString += `; expires=${date.toUTCString()}`;
    }

    cookieString += `; path=${path}`;

    if (secure) {
        cookieString += "; Secure";
    }

    if (sameSite) {
        cookieString += `; SameSite=${sameSite}`;
    }

    document.cookie = cookieString;
};
