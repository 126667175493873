import constants from "./constants";

const fetchNodeContent = async (topic, subtopic = '', start = false) => {
    const response = await fetch(
        `${constants.fetchNodeContent}?topic=${topic}${subtopic ? '&subtopic=' + subtopic : ''}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: "include"
    });

    if (!response.ok) {
        response.message = await response.json();
        throw response;
    }

    return await response.json();
}

export default fetchNodeContent