import React, { useEffect, useState } from 'react';
import '../style/TopicsGrid.css';
import { IoCloseCircle } from 'react-icons/io5';

const TopicsGridHistory = () => {
  const [lastClickedTopics, setLastClickedTopics] = useState([]);

  useEffect(() => {
    const savedTopics = JSON.parse(localStorage.getItem("lastClickedTopics")) || [];
    setLastClickedTopics(savedTopics);
  }, []);

  const handleRemoveTopic = (topicName) => {
    const updatedTopics = lastClickedTopics.filter((topic) => topic.name !== topicName);
    setLastClickedTopics(updatedTopics);
    localStorage.setItem("lastClickedTopics", JSON.stringify(updatedTopics));
  };

  if (lastClickedTopics.length === 0) {
    return;
  }

  return (
    <div className="topics-grid-container">
      <div className="topics-grid">
        {lastClickedTopics.map((topic, index) => (
          <div key={index} className="topic-button-wrapper">
            <div
              onClick={() => (window.location.href = `/${topic.name}`)}
              key={index}
              className="topic-card d-flex"
            >
              <div>
                <div className="emoji">{topic.emoji}</div>
                <div className="name">{topic.name}</div>
              </div>
            </div>
            <button
              className="remove-button"
              onClick={() => handleRemoveTopic(topic.name)}
            >
              <IoCloseCircle />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopicsGridHistory;
